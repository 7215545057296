import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"
import Carousel from "react-multi-carousel"
import { Link } from "gatsby-plugin-react-intl"

import { useIntl } from "gatsby-plugin-react-intl"

import * as styles from "../styles/components/partners.module.scss"

// React Multi Carousel Breakpoints
const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 1440, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

export default function Partners() {
  const intl = useIntl()

  return (
    <>
      <Container fluid className={`text-center my-2`}>
        <Row className={` my-5 justify-content-center gx-5`}>
          <Col lg={10}>
            <Row>
              <h2 className={`text-grey`}>
                <strong>{intl.formatMessage({ id: "deliveringBaby" })}</strong>
              </h2>
              <p>{intl.formatMessage({ id: "deliveringBabyDesc" })}</p>
            </Row>

            <Row className="my-2 g-5">
              <Carousel
                sliderClass="align-items-center"
                autoPlay={true}
                autoPlaySpeed={2500}
                draggable={true}
                responsive={responsive}
                infinite={true}
                keyBoardControl={true}
                arrows={false}
                swipeable={true}
                className={styles.carousel}
              >
                <a href="https://hcahealthcare.com/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/hca.png"
                      alt="hca"
                      width={150}
                    />
                  </Col>
                </a>
                <a
                  href="https://www.methodisthospital.org/About-Us/History.aspx"
                  target="_blank"
                >
                  <Col>
                    <StaticImage
                      src="../images/partners/methodist.png"
                      alt="methodist"
                      width={150}
                    />
                  </Col>
                </a>
                <a href="https://www.cedars-sinai.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/cedars-sinai.png"
                      alt="cedars"
                      width={150}
                    />
                  </Col>
                </a>
                <a href="https://www.providence.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/providence.png"
                      alt="providence"
                      width={150}
                    />
                  </Col>
                </a>
                <a href="https://www.chla.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/childrens.png"
                      alt="childrens"
                      width={150}
                    />
                  </Col>
                </a>

                <a href="https://www.valleypres.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/valley-hospital.jpg"
                      alt="valley"
                      width={150}
                    />
                  </Col>
                </a>
                <a href="https://www.dignityhealth.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/dignity-health.jpg"
                      alt="dignity"
                      width={150}
                    />
                  </Col>
                </a>

                <a href="https://www.uclahealth.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/ucla-health-logo.svg"
                      alt="ucla"
                      width={150}
                    />
                  </Col>
                </a>

                <a href="https://www.huntingtonhealth.org/" target="_blank">
                  <Col>
                    <StaticImage
                      src="../images/partners/huntington-hospital.png"
                      alt="huntington"
                      width={150}
                    />
                  </Col>
                </a>
              </Carousel>
            </Row>

            <Link to="/signature-services/expectant-parents" className="mt-4">
              <Button>{intl.formatMessage({ id: "learnMore" })}</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  )
}
