import React, { useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"

import * as styles from "../styles/components/search.module.scss"

export default function Search() {
  const [location, setLocation] = useState("")
  const [doctor, setDoctor] = useState("")

  const data = useStaticQuery(graphql`
    query {
      allContentfulLocation {
        edges {
          node {
            location
            doctors {
              name
              slug
            }
          }
        }
      }
    }
  `)

  const handleSetLocation = e => {
    const { value } = e.target
    const locationExists = data.allContentfulLocation.edges.find(edge =>
      value.includes(edge.node.location.trim())
    )
    if (!locationExists) {
      return
    }

    setLocation(value)
    setDoctor(locationExists.node.doctors[0].name)
  }

  const handleSetDoctor = e => {
    const { value } = e.target
    if (value === "Select Doctor") {
      return
    }
    setDoctor(value)
  }

  const handleSubmit = () => {
    const newData = data.allContentfulLocation.edges.find(edge =>
      location.includes(edge.node.location)
    )

    const doc = newData.node.doctors.find(doc => doc.name === doctor)
    navigate(`/providers/${doc.slug}`)
  }

  return (
    <Container fluid className={`${styles.container} py-5`}>
      <Container className="text-center">
        <Row>
          <Col md={8} className="mx-auto mb-5">
            <h1>
              <strong>
                <span className="text-warning">Our very own IPA </span>
                guarantees your child is given pediatric medical care at any of
                our{" "}
                <span className="text-warning">
                  multiple locations across Los Angeles
                </span>{" "}
                county
              </strong>
            </h1>
          </Col>
        </Row>

        <Row className="justify-content-center g-md-2">
          <Col lg={10} className="justify-content-center">
            <Form className={styles.form}>
              <Row>
                <Col md={3} className="mb-4">
                  <Form.Group>
                    <Form.Select
                      onChange={handleSetLocation}
                      value={location}
                      size="lg"
                      required
                      name="location"
                    >
                      <option>Select Location</option>
                      {data.allContentfulLocation.edges.map(location => (
                        <option key={location.node.location}>
                          {location.node.location}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4} className="mb-4">
                  <Form.Group>
                    <Form.Select
                      onChange={handleSetDoctor}
                      value={doctor}
                      size="lg"
                      required
                      name="doctor"
                      placeholder="Doctor"
                    >
                      <option>Select Doctor</option>
                      {/* Find node by Location and then map doctors */}
                      {location &&
                        data.allContentfulLocation.edges
                          .find(edge =>
                            location.includes(edge.node.location.trim())
                          )
                          .node.doctors.map(doctor => (
                            <option key={doctor.name} value={doctor.name}>
                              {doctor.name}
                            </option>
                          ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-4">
                  <Button
                    variant="success"
                    size="lg"
                    disabled={!doctor}
                    submit
                    onClick={handleSubmit}
                  >
                    Search Now
                  </Button>
                </Col>

                <Col md={2} className="mb-4">
                  <Link to="/doctors">
                    <Button variant="info" size="lg">
                      View All
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
