import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Partners from "../components/partners"
import About from "../components/About/about"
import Search from "../components/search"

const IndexPage = () => (
  <Layout>
    <Seo title="Our Partners" />
    <About />
    <Search />
    <Partners hr={false} />
  </Layout>
)

export default IndexPage
